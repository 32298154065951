import React from 'react'

import Layout from '@components/layout/Layout'
import ReferenceComponent from '@components/references/reference.component'
import LearnMoreCardComponent from '@components/learn-more-card/learn-more-card.component'
import SiteLink from '@components/SiteLink'
import LightBulb from '@assets/images/icons/learn-more-icon.svg'
import { references } from '@components/references/pediatric-differential-diagnosis-references'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './differential-diagnosis.scss'

const DifferentialDiagnosis = ({ location }) => {

  const adhd = {
    title: 'Distinguish Narcolepsy',
    content: (
      <div>
        Patients with narcolepsy may have difficulty maintaining sleep but fall asleep easily,<sup>9</sup> while
        patients with ADHD can demonstrate significantly increased objective latency to sleep onset and subjective sleep
        onset difficulties.<sup>10</sup>
      </div>
    ),
    icon: LightBulb
  }

  const cataplexy = {
    title: 'Distinguish Narcolepsy',
    content: (
      <div>
        <ul>
          <li>
            <span>
              Consciousness is maintained during a cataplexy attack.<sup>5</sup>
            </span>
          </li>
          <li>
            <span>
              Electroencephalogram abnormalities are present during epileptic attacks and in between attacks.<sup>
                5
              </sup>
            </span>
          </li>
          <li>
            <span>
              Triggers for cataplexy attacks are typically emotions; triggers for reflex seizures are usually sensory
              stimuli, such as light, photostimulation, or touch.<sup>5</sup>
            </span>
          </li>
        </ul>
      </div>
    ),
    icon: LightBulb
  }

  const polysomnography = {
    title: 'Distinguish Narcolepsy',
    content: (
      <div>
        Objective measures of sleep characteristics using polysomnography (PSG)/multiple sleep latency test (MSLT) can help differentiate from sleepiness associated with mood disorders.<sup>3,7</sup>
      </div>
    ),
    icon: LightBulb
  }

  const otherSymptoms = {
    title: 'Distinguish narcolepsy',
    content: (
      <div>
        <ul>
          <li>
            <span>
              Other symptoms of narcolepsy, such as hypnagogic hallucinations, automatic behavior, sleep paralysis, and excessive and unusual dreaming, are present in addition to excessive daytime sleepiness.<sup>12</sup>
            </span>
          </li>
          <li>
            <span>Cataplexy is present.<sup>12</sup></span>
          </li>
        </ul>
      </div>
    ),
    icon: LightBulb
  }

  return (
    <Layout addedClass='page-pediatrics-differential-diagnosis' location={location}>
      <div className="pediatrics-differential-diagnosis-page-header">
        <div className="primary-container">
          <div className="pediatrics-differential-diagnosis-header-text">
            <h1>Differential Diagnosis for Pediatric Patients</h1>
            <p>
              Narcolepsy in pediatric patients is often misdiagnosed as a more common medical condition, such as attention-deficit/hyperactivity disorder (ADHD), epilepsy, depression, syncope, or other sleep disorders.<sup>1-5</sup> Further complicating diagnosis, children with narcolepsy commonly present with behavioral or mood disorders, such as ADHD, depression, and anxiety, which may be part of the clinical spectrum of the disease itself, reactive to the disease, or a comorbid psychiatric condition.<sup>3,6,7</sup>
            </p>
            <br/>
            <p><b>When making a differential diagnosis, it’s important to remember that narcolepsy is a rare illness that can sometimes be comorbid to other conditions.</b><sup>1,3,6,7</sup></p>
          </div>
        </div>
        <div
          className="pediatrics-differential-diagnosis-image-container"
          role="image"
          aria-label="misdiagnosis for pediatric patients image"
        />
      </div>
      <div className="background-white">
        <section className="section-gradient" />
      </div>
      <div className="primary-container content-wrapper">
        <div className="content-wrapper-header">
          <h2 className="section-header">Distinguishing Narcolepsy From More Common Pediatric Conditions</h2>
        </div>
        <div className="primary-card symptom-card">
          <div className="symptom-card-column text-container">
            <h3>Narcolepsy and/or ADHD?</h3>
            <p>
              Excessive daytime sleepiness in narcolepsy can be similar to symptoms of ADHD.<sup>7,8</sup>
            </p>
            <ul>
              <li>
                <span>
                  Children with excessive daytime sleepiness may present as aggressive, irritable, or hyperactive in an
                  attempt to cope with or counteract sleepiness.<sup>3,7</sup>
                </span>
              </li>
              <li>
                <span>
                  Excessive daytime sleepiness is frequently mislabeled as laziness or can manifest as hyperactivity,
                  inattention, or behavioral problems, which may be misdiagnosed as ADHD.<sup>7</sup>
                </span>
              </li>
              <li>
                <span>
                  Treatment of ADHD may improve sleepiness-related hyperactivity, further confounding the narcolepsy
                  diagnosis.<sup>1,3</sup>
                </span>
              </li>
            </ul>
          </div>
          <div className="symptom-card-column learn-more-container">
            <LearnMoreCardComponent learnMoreCard={adhd} />
          </div>
        </div>
        <div className="primary-card symptom-card">
          <div className="symptom-card-column text-container">
            <h3>Narcolepsy and/or Epilepsy?</h3>
            <p>
              The sudden, recurrent, and intensifying nature of cataplexy may be confused with a seizure disorder.<sup>
                5,11,12
              </sup>
            </p>
            <ul>
              <li>
                <span>
                  Sudden loss of muscle tone associated with cataplexy can be mistaken as seizures.<sup>5</sup>
                </span>
              </li>
              <li>
                <span>
                  Cataplexy attacks that present as asymmetric loss of muscle tone or facial cataplexy that resembles
                  twitching may be mistaken for focal seizures.<sup>5</sup>
                </span>
              </li>
              <li>
                <span>
                  Microsleeps can clinically resemble absence seizures.<sup>5</sup>
                </span>
              </li>
            </ul>
          </div>
          <div className="symptom-card-column learn-more-container">
            <LearnMoreCardComponent learnMoreCard={cataplexy} />
          </div>
        </div>
        <div className="primary-card symptom-card">
          <div className="symptom-card-column text-container">
            <h3>Narcolepsy and/or Mood Disorder?</h3>
            <p>
              Several symptoms associated with narcolepsy may be attributed to depression, anxiety, or other psychiatric
              disorders.
            </p>
            <ul>
              <li>
                <span>
                  Children with narcolepsy frequently present as overweight/obese, particularly close to disease onset.<sup
                  >
                    12,13
                  </sup>
                </span>
              </li>
              <li>
                <span>
                  Symptoms such as fatigue, disrupted nocturnal sleep, and weight change may be attributed to mood
                  disorders.<sup>7</sup>
                </span>
              </li>
              <li>
                <span>
                  Hypnagogic hallucinations may be confused with night terrors, nightmares, or panic attacks, or they
                  may be mistaken for symptoms of psychiatric disorders.<sup>2</sup>
                </span>
              </li>
            </ul>
          </div>
          <div className="symptom-card-column learn-more-container">
            <LearnMoreCardComponent learnMoreCard={polysomnography} />
          </div>
        </div>
        <hr />
        <div className="primary-card symptom-card">
          <div className="symptom-card-column text-container">
            <h3>Narcolepsy and/or OSA?</h3>
            <p>
              Weight gain is common at the onset of narcolepsy, which may predispose patients to develop
              sleep-disordered breathing.<sup>12,13</sup> As a result, these patients may receive a diagnosis of
              obstructive sleep apnea (OSA).<sup>12</sup> Although OSA and narcolepsy can occur together, misdiagnosis
              of OSA as the primary cause of sleepiness can cause a delay in the diagnosis of narcolepsy.<sup>12</sup>
            </p>
          </div>
          <div className="symptom-card-column learn-more-container">
            <LearnMoreCardComponent learnMoreCard={otherSymptoms} />
          </div>
        </div>
        <h2 className="section-header">Other Common Misdiagnoses</h2>
        <div className="other-common-diagnosis">
          <h3>Myopathy</h3>
          <ul>
            <li>
              <span>
                Close to disease onset, cataplexy can mimic a spectrum of motor disorders and muscle diseases resulting
                in a misdiagnosis of myopathy.<sup>7,14</sup>
              </span>
            </li>
          </ul>
          <h3>Syncope</h3>
          <ul>
            <li>
              <span>
                Loss of muscle tone and rapid recovery associated with cataplexy may be confused with syncope.<sup>
                  5
                </sup>
              </span>
            </li>
            <li>
              <span>
                To differentiate cataplexy attacks from syncope, thorough screening for cardiac arrhythmias, head up
                tilt table testing, and video recordings of the attacks may be necessary.<sup>5</sup>
              </span>
            </li>
            <li>
              <span>
                Preserved consciousness distinguishes cataplexy from syncope.<sup>5</sup>
              </span>
            </li>
          </ul>
          <h3>Sydenham Chorea and PANDAS</h3>
          <ul>
            <li>
              <span>
                Sydenham chorea and pediatric autoimmune neuropsychiatric disorder associated with streptococcal
                infections (PANDAS) are brain autoimmune poststreptococcal diseases that occur in pediatric patients.<sup
                >
                  14
                </sup>
              </span>
            </li>
            <li>
              <span>
                Narcolepsy with cataplexy is often confused with Sydenham chorea or PANDAS due to overlap of certain characteristics, such as episodic course, childhood onset with acute presentation following streptococcal infection, and coexistence of motor and behavioral symptoms that present similarly to cataplexy in narcolepsy in pediatric patients.<sup>14</sup>
              </span>
            </li>
          </ul>
          <div className="pediatric-symptom-note">
            <p>Consider narcolepsy for pediatric patients presenting with:</p>
            <ul>
              <li>
                <span>
                  Manifestations of excessive daytime sleepiness and abnormal motor phenomena
                  <sup className="not-italic" >2,9,15</sup>
                </span>
              </li>
              <li>
                <span>
                  Excessive sleepiness with episodic loss of muscle tone, poor attention, and/or weight gain
                  <sup className="not-italic" >16</sup>
                </span>
              </li>
            </ul>
          </div>
        </div>
        <SiteLink 
          className="cta-tile-button btn-primary"
          to="/pediatric-patients/screening/"
          tracking="pediatric differential diagnosis, click, pediatric screening - differential diagnosis"
          liveRampTracking={true}
        >
          <div className="cta-tile-button-text">
            Read Next: <span style={{ textTransform: 'none', fontWeight: 'normal' }}>Screening</span>&nbsp;&nbsp;
          </div>
          <FontAwesomeIcon className="arrow-right" icon="angle-right" />
        </SiteLink>
      </div>
      <ReferenceComponent referenceList={references} />
    </Layout>
  )
}

export default DifferentialDiagnosis
