export const references = [
  {
    content:
      'Maski K, Owens JA. Insomnia, parasomnias, and narcolepsy in children: clinical features, diagnosis, and management.',
    source: 'Lancet Neurol.',
    info: '2016;15(11):1170-1181.'
  },
  {
    content: 'Babiker MOE, Prasad M. Narcolepsy in children: a diagnostic and management approach.',
    source: 'Pediatr Neurol.',
    info: '2015;52(6):557-565.'
  },
  {
    content: 'Rocca FL, Pizza F, Ricci E, Plazzi G. Narcolepsy during childhood: an update.',
    source: 'Neuropediatrics.',
    info: '2015;46(3):181-198.'
  },
  {
    content:
      'Carter LP, Acebo C, Kim A. Patients’ journeys to a narcolepsy diagnosis: a physician survey and retrospective chart review.',
    source: 'Postgrad Med.',
    info: '2014;126(3):216-224.'
  },
  {
    content:
      'Pillen S, Pizza F, Dhondt K, Scammell TE, Overeem S. Cataplexy and its mimics: clinical recognition and management.',
    source: 'Curr Treat Options Neurol.',
    info: '2017;19(6):23.'
  },
  {
    content:
      'Aran A, Einen M, Lin L, Plazzi G, Nishino S, Mignot E. Clinical and therapeutic aspects of childhood narcolepsy-cataplexy: a retrospective study of 51 children.',
    source: 'Sleep.',
    info: '2010;33(11):1457-1464.'
  },
  {
    content:
      'Postiglione E, Antelmi E, Pizza F, Lecendreux M, Dauvilliers Y, Plazzi G. The clinical spectrum of childhood narcolepsy.',
    source: 'Sleep Med Rev.',
    info: '2018;38:70-85.'
  },
  {
    content:
      'Maski K, Steinhart E, Williams D, et al. Listening to the patient voice in narcolepsy: diagnostic delay, disease burden, and treatment efficacy.',
    source: 'J Clin Sleep Med.',
    info: '2017;13(3):419-425.'
  },
  {
    content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In:',
    source: 'The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version.',
    info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
  },
  {
    content:
      'Cortese S, Faraone SV, Konofal E, Lecendreux M. Sleep in children with attention-deficit/hyperactivity disorder: meta-analysis of subjective and objective studies.',
    source: 'J Am Acad Child Adolesc Psychiatry.',
    info: '2009;48(9):894-908.'
  },
  {
    content: 'Serra L, Montagna P, Mignot E, Lugaresi E, Plazzi G. Cataplexy features in childhood narcolepsy.',
    source: 'Mov Disord.',
    info: '2008;23(6):858-865.'
  },
  {
    content:
      'Thorpy M, Morse AM. Reducing the clinical and socioeconomic burden of narcolepsy by earlier diagnosis and effective treatment.',
    source: 'Sleep Med Clin.',
    info: '2017;12(1):61-71.'
  },
  {
    content: 'Inocente CO, Lavault S, Lecendreux M, et al. Impact of obesity in children with narcolepsy.',
    source: 'CNS Neurosci Ther.',
    info: '2013;19(7):521-528.'
  },
  {
    content:
      'Plazzi G, Pizza F, Palaia V, et al. Complex movement disorders at disease onset in childhood narcolepsy with cataplexy.',
    source: 'Brain.',
    info: '2011;134(pt 12):3480-3492.'
  },
  {
    content: 'Green PM, Stillman MJ. Narcolepsy: signs, symptoms, differential diagnosis, and management.',
    source: 'Arch Fam Med.',
    info: '1998;7(5):472-478.'
  },
  {
    content: 'Kauta SR, Marcus CL. Cases of pediatric narcolepsy after misdiagnoses.',
    source: 'Pediatr Neurol.',
    info: '2012;47(5):362-365.'
  }
];